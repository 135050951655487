import { takeEvery, call, put, takeLatest } from "redux-saga/effects";
import {
	setContent,
	verifyDOBFailure,
	verifyDOBSuccess,
} from "../state/CareGapAction";
import { verifyDOBRequest } from "../service/CareGapService";

function* fetchContentSage(action) {
	try {
		const content = {
			id: action.payload.id,
			title: "Care Gap",
			description:
				"Before we get to screening questionnarie, we are need to confirm your identity.",
		};
		yield put(setContent(content));
	} catch (error) {
		console.error(error);
	}
}

function* verifyDOBSaga(action) {
	try {
		const { id, dob } = action.payload;
		const data = yield call(verifyDOBRequest, id, dob);
		yield put(verifyDOBSuccess(data));
	} catch (error) {
		yield put(verifyDOBFailure(error));
	}
}

export function* watchContent() {
	yield takeEvery("FETCH_CONTENT", fetchContentSage);
}

export function* watchDOBVerification() {
	yield takeLatest("VERIFY_DOB", verifyDOBSaga);
}
